import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';

import 'semantic-ui-css/semantic.min.css';

import RootNode from './rootNode';

const container = document.getElementById('root');

// Official React docs say to use ! when passing container to createRoot():
// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<RootNode />);

ReactModal.setAppElement('#root');
