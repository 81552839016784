import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import _ from 'lodash';
import { DateTime } from 'luxon';
import css from './DetailsModal.module.css';
import { SimpleStateDispatch } from './PopupManager';
import { calendarDate } from 'lib/time';
import { Store } from 'types/redux';
import { Popup, PopupState, TimeZone } from 'types/tables/popups';
import { fetchSponsorDivisions, getSponsorById, getSponsorDivisionsById } from 'reducers/sponsors';
import { athenaProvidersSelectors } from 'reducers/athenaProviders';
import { XIcon } from 'components/ui/svg';
import { BodyNormal, BodySmall, HeaderMedium } from 'components/design-system/Text';
import { PrimaryButton } from 'components/design-system/Form';
import { popupReasonsSelectors } from 'reducers/popupReasons';
import { AppointmentReason } from 'types/athena/appointmentReason';
import { EdenColors } from 'types/colors';
import { useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { getDivisionNameById, getDivisionsWithNull } from 'lib/util';

export interface DetailsModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  popup?: Popup;
  past: boolean;
  simpleStateDispatch: SimpleStateDispatch;
}

export const isRecurringPopup = (popup) => {
  return popup?.endDate && popup?.date != popup?.endDate;
};

export const formatPopupState = (past: boolean, popup?: Popup) => {
  if (!popup) return '';
  if (past) return 'Past Event';
  return popup.popupState[0].toUpperCase() + popup.popupState.slice(1);
};

export const formatPopupTimes = (startTime?: string, endTime?: string, zone?: TimeZone) => {
  if (startTime && endTime && zone) {
    const st = DateTime.fromISO(startTime, { zone });
    const et = DateTime.fromISO(endTime, { zone });
    return `${st.toLocaleString(DateTime.TIME_SIMPLE)} - ${et.toLocaleString(
      DateTime.TIME_SIMPLE,
    )} ${st.offsetNameShort}`;
  } else {
    return '';
  }
};

export const formatPopupDateTimes = (startTime: string, endTime: string, zone: TimeZone) => {
  const dt = calendarDate(startTime, { setZone: true, showYear: true });
  const st = DateTime.fromISO(startTime, { zone });
  const et = DateTime.fromISO(endTime, { zone });
  return `${dt} ${st.toLocaleString(DateTime.TIME_SIMPLE)} - ${et.toLocaleString(
    DateTime.TIME_SIMPLE,
  )} ${st.offsetNameShort}`;
};

export const formatPopupAddress = (popup?: Popup) => {
  if (!popup) return '';
  return `${popup.address1}${popup.address2 ? ` ${popup.address2}` : ''}, ${popup.city}, ${
    popup.state ?? ''
  } ${popup.zip}`;
};

export const formatPopupAppointmentLength = (popupReason?: AppointmentReason) => {
  if (!popupReason) return '';
  if (popupReason.description.match(/flu/i)) {
    return '5 minute appointments';
  }
  if (popupReason.description.match(/primary/i)) {
    return '30 minute appointments';
  }
  return '';
};

export const DetailsModal = ({
  isOpen,
  closeModal,
  popup,
  past,
  simpleStateDispatch,
}: DetailsModalProps) => {
  const dispatch = useAppDispatch();

  const schedulingProvider = useSelector((state: Store) =>
    athenaProvidersSelectors.selectById(state, popup?.athenaSchedulingProviderId ?? 0),
  );
  const sponsor = useParameterizedSelector(getSponsorById, popup?.sponsorId ?? '');
  const popupReason = useSelector((state: Store) =>
    popupReasonsSelectors.selectById(state, popup?.athenaReasonId ?? 0),
  );

  const fetchDivisions = useCallback(async () => {
    if (popup && popup.sponsorId) {
      await dispatch(fetchSponsorDivisions(popup.sponsorId));
    }
  }, [dispatch, popup]);

  useEffect(() => {
    fetchDivisions();
  }, [fetchDivisions]);

  const sponsorDivisions = useSelector((state: Store) =>
    getSponsorDivisionsById(state, popup?.sponsorId ?? ''),
  );

  const sponsorDivisionsWithNull = getDivisionsWithNull(sponsorDivisions);

  const displaySubpopulations = (divisionIds) => {
    return divisionIds && divisionIds.length > 0
      ? divisionIds.reduce((acc, divisionId, index) => {
          if (index < divisionIds.length - 1) {
            return acc + getDivisionNameById(divisionId, sponsorDivisionsWithNull) + ', ';
          } else {
            return acc + getDivisionNameById(divisionId, sponsorDivisionsWithNull);
          }
        }, '')
      : 'None';
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onClose={closeModal}
      className={css.container}
      overlayClassName={css.overlay}
    >
      <div className={css.topNav}>
        <div onClick={closeModal} style={{ cursor: 'pointer' }}>
          <XIcon color={EdenColors.Slate55} height={24} width={24} />
        </div>
      </div>
      <div className={css.content} data-testid="popup-details">
        <BodyNormal className={css.popupState}>{formatPopupState(past, popup)}</BodyNormal>
        <div className={css.sponsorRow}>
          <HeaderMedium className={css.title}>{popup?.name}</HeaderMedium>
          <PrimaryButton
            data-testid="edit-popup-button"
            className={css.editButton}
            value="Edit"
            disabled={past}
            onClick={() => {
              simpleStateDispatch('openEditPopup', { popup });
            }}
          />
        </div>
        <div className={css.details}>
          <div className={css.detailsLabel}>Sponsor</div>
          <div className={css.detailsValue}>{sponsor?.name}</div>
          {sponsorDivisions && sponsorDivisions.length > 0 && popup && (
            <>
              <div className={css.detailsLabel}>Subpopulations</div>
              <div className={css.detailsValue}>{displaySubpopulations(popup.divisionIds)}</div>
            </>
          )}
          <div className={css.detailsLabel}>{isRecurringPopup(popup) ? 'Dates' : 'Date'}</div>
          <div className={css.detailsValue}>
            {isRecurringPopup(popup)
              ? `${calendarDate(popup?.date, { showYear: true, setZone: true })} - ${calendarDate(
                  popup?.endDate,
                  {
                    showYear: true,
                    setZone: true,
                  },
                )}`
              : calendarDate(popup?.date, { showYear: true, setZone: true })}
          </div>
          <div className={css.detailsLabel}>Address</div>
          <div className={css.detailsValue}>{formatPopupAddress(popup)}</div>
          <div className={css.detailsLabel}>Timezone</div>
          <div className={css.detailsValue}>{popup?.timeZoneId}</div>
          <div className={css.detailsLabel}>Pop-up Type</div>
          <div className={css.detailsValue}>{popupReason?.description}</div>
          <div className={css.detailsLabel}>Eligible Members</div>
          <div className={css.detailsValue}>{_.startCase(popup?.eligibleMembers)}</div>
          <div className={css.detailsLabel}>Scheduling Provider</div>
          <div className={css.detailsValue}>
            <div>{schedulingProvider?.displayName ?? 'Not Set'}</div>
            {isRecurringPopup(popup) ? (
              popup?.popupDates &&
              popup?.popupDates.length > 0 &&
              popup.popupDates.map((popupDate) => (
                <div className={css.light} key={popupDate.startTime}>
                  {formatPopupDateTimes(popupDate.startTime, popupDate.endTime, popup.timeZoneId)}
                </div>
              ))
            ) : (
              <div className={css.light}>
                {formatPopupTimes(popup?.startTime, popup?.endTime, popup?.timeZoneId)}
              </div>
            )}
            <div className={css.light}>{formatPopupAppointmentLength(popupReason)}</div>
          </div>
          <div className={css.detailsLabel}>Instructions</div>
          <div className={css.detailsValue}>{popup?.instructions}</div>
        </div>
      </div>
      <div className={css.bottomNav}>
        {!past && popup?.popupState === PopupState.draft ? (
          <BodySmall
            className={css.deleteEvent}
            data-testid="details-delete-button"
            onClick={() => simpleStateDispatch('openDeleteModal', popup)}
          >
            Delete Event
          </BodySmall>
        ) : (
          <div />
        )}
        {!past ? (
          <PrimaryButton
            value={popup?.popupState === PopupState.draft ? 'Activate' : 'Deactivate'}
            onClick={() =>
              popup?.popupState === PopupState.draft
                ? simpleStateDispatch('openActivateModal', popup)
                : simpleStateDispatch('openDeactivateModal', popup)
            }
            className={css.activate}
            disabled={past}
            data-testid={
              popup?.popupState === PopupState.draft
                ? 'details-activate-button'
                : 'details-deactivate-button'
            }
          />
        ) : null}
      </div>
    </ReactModal>
  );
};
