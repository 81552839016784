import { DateTime } from 'luxon';

interface Options {
  showYear?: boolean;
  setZone?: boolean;
}

export const calendarDate = (
  value?: string,
  { showYear, setZone }: Options = {
    showYear: false,
    setZone: false,
  },
): string => {
  if (!value) {
    return 'Invalid Date';
  }

  const date = DateTime.fromISO(value, { setZone: setZone });

  return date.toFormat('MMMM').length <= 3
    ? date.toFormat(showYear ? 'MMM d, yyyy' : 'MMM d')
    : date.toFormat(showYear ? 'MMM. d, yyyy' : 'MMM. d');
};
