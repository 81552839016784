import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { ForeignKey, PrimaryKey } from 'types/tables/base';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { Chart, SectionType } from 'types/tables/charts';
import { Store } from 'types/redux';

const prefix = 'chart';

export const chartsAdapter = createEntityAdapter<Chart>({});

export const fetchChart = createAsyncThunk(
  `${prefix}/fetchChart`,
  async (patientId: PrimaryKey) => {
    const response = await GrdnApi.chart(patientId);
    if (isSuccessResponse(response)) {
      return {
        id: patientId,
        [SectionType.alert]: response.data.alert,
        [SectionType.allergy]: response.data.allergies,
        [SectionType.medication]: response.data.medications,
        [SectionType.problem]: response.data.problems,
      };
    } else {
      return {
        id: patientId,
        [SectionType.alert]: { error: 'error' },
        [SectionType.allergy]: { error: 'error' },
        [SectionType.medication]: { error: 'error' },
        [SectionType.problem]: { error: 'error' },
      };
    }
  },
);

export const charts = createSlice({
  name: prefix,
  initialState: chartsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChart.fulfilled, (state, action) => {
      chartsAdapter.upsertOne(state, action.payload);
    });
  },
});

export const chartsSelectors = chartsAdapter.getSelectors<Store>((state) => state.charts);

export const getCharts = (state) => chartsSelectors.selectEntities(state);
export const getChartIds = (state) => chartsSelectors.selectIds(state);

export const getPatientChart = createSelector(
  (_, patientId: ForeignKey) => patientId,
  chartsSelectors.selectAll,
  (patientId, charts: Chart[]) => {
    return charts.find((chart) => chart?.id === patientId);
  },
);

export default charts.reducer;
