import { useDebugValue } from 'react';

export const loadEnv = (variable: string): string => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _window = window as any;
  const possiblyDefinedEnvVar = _window._env_
    ? _window._env_[variable]
      ? _window._env_[variable]
      : process.env[variable]
    : process.env[variable];

  return possiblyDefinedEnvVar ?? '';
};

export type BuildEnvironment = 'Production' | 'Staging' | 'Development' | 'Integration';

export const getEnvironment = (): BuildEnvironment =>
  `${loadEnv('REACT_APP_BUILD_ENVIRONMENT')}` as BuildEnvironment;
export const getAppName = () => loadEnv('REACT_APP_NAME');
export const getReleasePrefix = () => loadEnv('REACT_APP_RELEASE_PREFIX');
const getVersion = () => loadEnv('REACT_APP_VERSION');

export const getRelease = () => {
  const environment = getEnvironment();
  const prefix = getReleasePrefix();
  const release = `${getVersion()}`;

  switch (environment) {
    case 'Production':
    case 'Staging':
      return `${prefix}-${release}`;
    case 'Development':
      return `${prefix}-${release}-local`;
    case 'Integration':
      return `${prefix}-${release}-testing`;
  }
};

export const useReleaseName = () => {
  const release = getRelease();
  useDebugValue(release);

  return release;
};

// Base path where CFE can use OFE's static assets.
export const getOpsAssetPath = () => loadEnv('REACT_APP_OPS_URL_BASE') || '';

export const opsAssetPath = (relativeUrl) => getOpsAssetPath() + relativeUrl;
