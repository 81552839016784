import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Store } from 'types/redux';
import { getMyProviderId } from 'reducers/user';
import { ChannelInterface } from 'types/sendbird';
import { Patient, PatientLoadState } from 'types/tables/patients';
import { ChannelStatus, ChannelType, InternalChannel, PrimaryChannel } from 'types/tables/channels';
import { Message, PendingMessage, PendStatus } from 'types/tables/messages';
import { INSERT_PENDING_MESSAGES, REMOVE_PENDING_MESSAGES } from 'legacy/actions/db';
import { RoomType } from 'types/sendbird/chat';
import { Dispatch } from 'store';
import app from 'reducers/app';
import {
  getPatientByMrn,
  getPatientsEntities,
  loadPatientByMrn,
  patients,
} from 'reducers/patients';
import { channels, joinPatientChannels } from 'reducers/channels';
import { messages } from 'reducers/messages';

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay]);
};

export const usePrevious = <T>(val: T): T | undefined => {
  /*
   * use to get the previous value of a prop or state field
   *
   * in a class based component, life cycle methods componentDidUpdate recieves an argument prevProps
   * allowing you to compare new props with old props. Use this hook to replicate that functionality,
   * particularly if you need previous values in a useEffect call
   *
   * see: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
   * */

  const ref = useRef<T>();
  useEffect(() => {
    ref.current = val;
  }, [val]); // Only re-run if value changes;
  return ref.current;
};

export const useActiveMRN = () => {
  const location = useLocation();
  const pathSearchResults = location.pathname.match(/\/app\/messages\/(.+)/);
  return pathSearchResults ? parseInt(pathSearchResults[1]) : NaN;
};

export const useNetwork = () => {
  const dispatch = useAppDispatch();
  const updateNetwork = () => {
    if (window.navigator.onLine) {
      dispatch(app.actions.networkOnline());
    } else {
      dispatch(app.actions.networkOffline());
    }
  };
  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });
};

/**
 * Returns function to send a text message, managing pending and failed states.
 */
export const useSendTextMessage = (patient: Patient) => {
  const dispatch = useAppDispatch();
  const { channel, sbChannel } = useActiveChat();
  const myProviderId = useSelector(getMyProviderId);

  return (pendingMessage: PendingMessage) => {
    const timestamp = Date.now();
    dispatch(INSERT_PENDING_MESSAGES(channel.id, [{ ...pendingMessage, timestamp }]));
    sbChannel
      .sendTextMessage({ text: pendingMessage.data.text })
      .then((message) => {
        dispatch(
          messages.actions.upsertMany({
            channelId: channel.id,
            entities: [message],
          }),
        );
        dispatch(REMOVE_PENDING_MESSAGES(channel.id, [pendingMessage.id]));
        if (channel.channelType === ChannelType.Primary) {
          dispatch(
            channels.actions.updateOne({
              id: channel.id,
              changes: {
                status: ChannelStatus.Pending,
              },
            }),
          );
          dispatch(
            patients.actions.updateOne({
              id: patient.id,
              changes: {
                assigned: patient.assigned
                  ? _.uniq([...patient.assigned, myProviderId || ''])
                  : [myProviderId || ''],
              },
            }),
          );
        }
        return sbChannel.stopTyping();
      })
      .catch(() => {
        const failedMessage = { ...pendingMessage, pendStatus: PendStatus.Failed, timestamp };
        dispatch(INSERT_PENDING_MESSAGES(channel.id, [failedMessage]));
      });
  };
};

// hooks that use or provide contexts

interface ActiveChat {
  channel: PrimaryChannel | InternalChannel;
  sbChannel: ChannelInterface;
  ready: boolean;
  canLoadEarlier: boolean;
  getMessages: ParametricSelector<Message[]>;
  getPendingMessages: ParametricSelector<PendingMessage[]>;
  getHasPendingMessages: ParametricSelector<boolean>;
}

export const ActiveChatContext = createContext<ActiveChat | undefined>(undefined);
ActiveChatContext.displayName = 'ActiveChannel';

export const useActiveChat = () => {
  const chat = useContext(ActiveChatContext);
  if (!chat) throw new Error('useActiveChat used without a provided ActiveChatContext');
  return chat;
};

export interface AppContextInterface {
  mobileChatRoom: RoomType;
  activePatientId?: string;
  toggleMobileChatRoom?: (roomType: RoomType) => void;
}

export const AppContext = createContext<AppContextInterface>({
  mobileChatRoom: RoomType.Patient,
  activePatientId: undefined,
  toggleMobileChatRoom: undefined,
});
AppContext.displayName = 'AppContext';

export const useActivePatient = (): Patient => {
  const app = useContext(AppContext);
  const patients = useSelector(getPatientsEntities);
  if (!app.activePatientId) {
    throw new Error('Attempt to useActivePatient without an active patient');
  }
  const patient = patients[app.activePatientId];
  if (patient === undefined) {
    throw new Error('Patient has not loaded');
  } else {
    return patient;
  }
};

export const connectToPatient = createAsyncThunk(
  'connectToPatient',
  async ({ mrn }: { mrn: number }, { dispatch, rejectWithValue }) => {
    try {
      // TODO: Remove any type
      const patient: any = await dispatch(loadPatientByMrn({ mrn })).unwrap();
      await dispatch(joinPatientChannels({ patient }));
      return patient;
    } catch (e) {
      return rejectWithValue({ rejectionType: 'Failed to connect to patient' });
    }
  },
);

export const useNavigateToPatient = (pathMrn: number) => {
  // TO-DO: write a test for this hook
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // TODO: Remove any type
  const currentPatient: any = useParameterizedSelector(getPatientByMrn, pathMrn);
  const [patientLoadState, setPatientLoadState] = useState<PatientLoadState>(() =>
    currentPatient?.id ? PatientLoadState.Loaded : PatientLoadState.NotLoaded,
  );

  const selectPatient = useCallback(
    async (mrn: number) => {
      // No-op if MRN matches one of the current patient's MRNs.
      if (mrn === pathMrn || currentPatient?.secondaryMrns?.includes(mrn)) {
        return;
      }
      setPatientLoadState(PatientLoadState.Switching);
      try {
        const patient = await dispatch(connectToPatient({ mrn })).unwrap();
        setPatientLoadState(PatientLoadState.Loaded);
        navigate(`/app/messages/${patient.mrn}`);
      } catch (e) {
        toast.error(`Unable to connect to ${mrn}`);
        setPatientLoadState(PatientLoadState.Error);
      }
    },
    [pathMrn, currentPatient?.secondaryMrns, dispatch, navigate],
  );

  return { patientLoadState, selectPatient };
};

export const useAppDispatch = () => useDispatch<Dispatch>();

export const useParameterizedSelector = <S>(
  selector: (state: Store, ...params: any[]) => S,
  ...params
): S => {
  /*
   * Replaces react-redux.useSelector for any parameterized selector
   * If your selector depends on information that is not in the store
   * e.g. if your component takes a prop and uses that prop in the selector
   * then you have a parameterized selector
   *
   * for a good explainer see: https://flufd.github.io/reselect-with-multiple-parameters/
   * */
  const partial = partialParameterizedSelector(selector, ...params);
  return useSelector<Store, S>(partial);
};

export type ParametricSelector<R> = (state: Store, ...args: any[]) => R;

export const partialParameterizedSelector = <S>(
  selector: (state: Store, ...params: any[]) => S,
  ...params
): ParametricSelector<S> => {
  /*
   * Occasionally, we build a context that includes selectors which will be called by children components.
   * The ActiveChatContext (defined above) context relies on this for `getMessages`, `getPendingMessages`, and `getHasPendingMessages`
   *
   * you can use this method to make a partial function where you know the parameter(s), but need to defer the selector
   * */

  return (state: Store) => selector(state, ...params);
};

export const useIsMounted = () => {
  /*
   * Returns the current state of whether or not the parent component is currently mounted
   *
   * Returned ref is required by exhaustive-deps rule but won't cause any re-renders
   * You can use this method to avoid memory leaks after unmount
   * */
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
};
