import _, { dropWhile } from 'lodash';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import { createAsyncThunk, createEntityAdapter, createSlice, Dictionary } from '@reduxjs/toolkit';
import { TableRecordType } from 'legacy/types/tables';
import {
  Channel,
  ChannelStatus,
  ChannelType,
  EventChannel,
  InternalChannel,
  PrimaryChannel,
  SidebarChannel,
} from 'types/tables/channels';
import { Store } from 'types/redux';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { getPatientsEntities } from 'reducers/patients';
import { Message, PendingMessage } from 'types/tables/messages';
import { PrimaryKey } from 'types/tables/base';
import {
  InsertAction,
  PendingMessagesInsertAction,
  PendingMessagesRemoveAction,
  RemoveAction,
  TableActionType,
} from 'legacy/types/actions';
import { getMessages, messages } from 'reducers/messages';
import { belongsInPrimaryChannel, formatPreferredFullNameFor, isSameDay } from 'lib/util';
import { Patient } from 'types/tables/patients';
import { JoinedChannelPayload } from 'types/grdn';
import { loadEnv } from 'lib/env';

export const getChannelBySendbirdUrlFromEntities =
  (sendbirdChannelUrl) => (channelEntities: Dictionary<Channel>) => {
    return Object.values(channelEntities).find(
      (channel) => channel?.sendbirdChannelUrl === sendbirdChannelUrl,
    );
  };

const prefix = 'channel';

const joinPatientChannelsRejectionType = 'Failed to join patient channels';

const routingClinicianProviderId = loadEnv('REACT_APP_POSTGRES_ROUTING_CLINICIAN_PROVIDER_ID');
const routingNavigatorProviderId = loadEnv('REACT_APP_POSTGRES_ROUTING_NAVIGATOR_PROVIDER_ID');
const routingMedicalAssistantProviderId = loadEnv(
  'REACT_APP_POSTGRES_ROUTING_MEDICAL_ASSISTANT_PROVIDER_ID',
);
const routingNursingProviderId = loadEnv('REACT_APP_POSTGRES_ROUTING_NURSING_PROVIDER_ID');
const routingProviderIds = [
  routingClinicianProviderId,
  routingNavigatorProviderId,
  routingMedicalAssistantProviderId,
  routingNursingProviderId,
];

export const transformJoinedChannel = (channel: JoinedChannelPayload): Channel => {
  const { id, patientId, status, channelType, sendbirdChannelUrl } = channel;
  const base = {
    id,
    patientId,
    messages: [],
    pendingMessages: [],
    lastRead: 0,
    typingMembers: [],
    channelType,
    isPatientOnline: false,
    sendbirdChannelUrl,
    updatedAt: DateTime.local().toISO({ suppressMilliseconds: true }),
    hasMoreMessages: false,
  };
  if (status) {
    return { ...base, status };
  } else {
    return base as Channel;
  }
};

export const channelsAdapter = createEntityAdapter<Channel>({});

export const changeChannelStatus = createAsyncThunk(
  `${prefix}/changeChannelStatus`,
  async ({ primaryChannel, status }: { primaryChannel: PrimaryChannel; status: ChannelStatus }) => {
    const response = await GrdnApi.updateChannel(primaryChannel.id, status);
    return {
      status,
      primaryChannelId: primaryChannel.id,
      isSuccessResponse: isSuccessResponse(response),
    };
  },
);

export const joinPatientChannels = createAsyncThunk(
  `${prefix}/joinPatientChannels`,
  async ({ patient }: { patient: Patient }, { rejectWithValue }) => {
    try {
      const joinChannelsResponse = await GrdnApi.joinPatientChannels(patient.id);
      if (isSuccessResponse(joinChannelsResponse)) {
        return joinChannelsResponse.data.channels.map(transformJoinedChannel);
      } else {
        return rejectWithValue({ rejectionType: joinPatientChannelsRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: joinPatientChannelsRejectionType });
    }
  },
);

export const channels = createSlice({
  name: prefix,
  initialState: channelsAdapter.getInitialState(),
  reducers: {
    updateOne: channelsAdapter.updateOne,
    addOne: channelsAdapter.addOne,
    upsertOne: channelsAdapter.upsertOne,
    upsertMany: channelsAdapter.upsertMany,
  },
  extraReducers: (builder) => {
    builder.addCase(
      changeChannelStatus.fulfilled,
      (state, { payload: { status, primaryChannelId, isSuccessResponse } }) => {
        if (isSuccessResponse) {
          channelsAdapter.updateOne(state, { id: primaryChannelId, changes: { status } });
        }
      },
    );
    builder.addCase(joinPatientChannels.fulfilled, (state, { payload: joinedChannels }) => {
      channelsAdapter.upsertMany(state, joinedChannels);
    });

    // Channels maintain a list of their associated messages, this builder listens for those messages being added
    // and updates the internal list of the channels. This denormalization is done for optimization purposes.
    builder.addCase(messages.actions.upsertMany, (state, { payload }) => {
      const channel = state.entities[payload.channelId];
      if (channel) {
        const messages = _.uniq(
          (channel.messages || []).concat(payload.entities.map((entity) => entity.id)),
        );
        channelsAdapter.updateOne(state, {
          id: channel.id,
          changes: { messages },
        });
      } else {
        throw new Error(`Cannot associate messages to nonexistent channel ${payload.channelId}`);
      }
    });

    builder.addCase(TableActionType.Insert, (state, action: InsertAction) => {
      if (action.tableRecordType === TableRecordType.PendingMessages) {
        const { records, channelId } = action as PendingMessagesInsertAction;
        const channel = state.entities[channelId];
        if (channel) {
          const pendingMessages = _.uniq(
            (channel.pendingMessages || []).concat(
              records.map((message: PendingMessage) => message.id),
            ),
          );
          channelsAdapter.updateOne(state, {
            id: channelId,
            changes: { pendingMessages },
          });
        } else {
          throw new Error(`Cannot associate pending messages to nonexistant channel ${channelId}`);
        }
      }
    });

    builder.addCase(TableActionType.Remove, (state, action: RemoveAction) => {
      if (action.tableRecordType === TableRecordType.PendingMessages) {
        const { recordIds, channelId } = action as PendingMessagesRemoveAction;
        const channel = state.entities[channelId];
        if (channel) {
          const pendingMessages = (channel.pendingMessages || []).filter(
            (id) => !recordIds.find((removedId) => id === removedId),
          );
          channelsAdapter.updateOne(state, {
            id: channelId,
            changes: { pendingMessages },
          });
        } else {
          throw new Error(`Cannot associate pending messages to nonexistant channel ${channelId}`);
        }
      }
    });
  },
});

// TODO: Selectors begin here. Move them into a selectors file.

export const channelsSelector = channelsAdapter.getSelectors<Store>((state) => state.channels);

export const getChannelIds = channelsSelector.selectIds;
export const getChannels = channelsSelector.selectAll;
export const getChannelsEntities = channelsSelector.selectEntities;

export const getPrimaryChannels = createSelector(
  getChannels,
  (channels) =>
    channels.filter(({ channelType }) => channelType === ChannelType.Primary) as PrimaryChannel[],
);

export const getPrimaryChannelIds = createSelector(getPrimaryChannels, (channels) =>
  channels.map(({ id }) => id),
);

export const getSidebarChannels = createSelector(
  getPrimaryChannels,
  getPatientsEntities,
  (records, patients) =>
    records
      .sort(
        (channel1, channel2) =>
          (channel2.lastMessage?.timestamp || DateTime.fromISO(channel2.updatedAt).toMillis()) -
          (channel1.lastMessage?.timestamp || DateTime.fromISO(channel1.updatedAt).toMillis()),
      )
      .reduce((sidebarChannels: SidebarChannel[], channel: PrimaryChannel) => {
        const patient = patients[channel.patientId];
        if (patient) {
          const patientName = formatPreferredFullNameFor(patient);
          return [...sidebarChannels, { channel, patient, patientName }];
        }
        return sidebarChannels;
      }, []),
);

const isAssigned = (patient: Patient) => patient.assigned.length > 0;

const isRouted = (patient: Patient) =>
  isAssigned(patient) && patient.assigned.find((provider) => routingProviderIds.includes(provider));

const isAssignedNotRouted = (patient: Patient) => isAssigned(patient) && !isRouted(patient);

export const getAssignedUnroutedSidebarChannels = createSelector(getSidebarChannels, (channels) =>
  channels.filter(
    ({ channel, patient }) =>
      channel.status !== ChannelStatus.Archived &&
      patient &&
      patient.assigned &&
      isAssignedNotRouted(patient),
  ),
);

export const getUnassignedSidebarChannels = createSelector(getSidebarChannels, (channels) =>
  channels.filter(
    ({ channel, patient }) =>
      channel.status !== ChannelStatus.Archived &&
      !(patient && patient.assigned && isAssigned(patient)),
  ),
);

export const getArchivedSidebarChannels = createSelector(getSidebarChannels, (channels) =>
  channels.filter(({ channel }) => channel.status === ChannelStatus.Archived),
);

export const getRoutedSidebarChannels = createSelector(getSidebarChannels, (channels) =>
  channels.filter(
    ({ channel, patient }) =>
      channel.status !== ChannelStatus.Archived && patient && patient.assigned && isRouted(patient),
  ),
);

export const getChannelBySendbirdUrl = createSelector(
  getChannelsEntities,
  (_, sendbirdChannelUrl: string) => sendbirdChannelUrl,
  (channelEntities: Dictionary<Channel>, sendbirdChannelUrl) => {
    return getChannelBySendbirdUrlFromEntities(sendbirdChannelUrl)(channelEntities);
  },
);

const extractPatientId = (_, patientId: PrimaryKey): PrimaryKey => patientId;

export const getPatientChannels = createSelector(
  extractPatientId,
  getChannels,
  (patientId, channels) => {
    return channels.filter((channel) => channel?.patientId === patientId);
  },
);

export const getPatientPrimaryChannel = createSelector(
  extractPatientId,
  getPatientChannels,
  (patientId, channels) => {
    const channel = channels.find((channel) => channel.channelType === ChannelType.Primary);
    if (!channel) throw new Error(`Primary Channel not in store for ${patientId}`);
    return channel as PrimaryChannel;
  },
);

export const getPatientEventChannel = createSelector(
  extractPatientId,
  getPatientChannels,
  (patientId, channels) => {
    const channel = channels.find((channel) => channel.channelType === ChannelType.Event);
    if (!channel) throw new Error(`Event Channel is not in store for ${patientId}`);
    return channel as EventChannel;
  },
);

export const getPatientInternalChannel = createSelector(
  extractPatientId,
  getPatientChannels,
  (patientId, channels) => {
    const channel = channels.find((channel) => channel.channelType === ChannelType.Internal);
    if (!channel) throw new Error(`Internal Channel not in store for ${patientId}`);
    return channel as InternalChannel;
  },
);

export const getPatientPrimaryChannelStatus = createSelector(
  getPatientPrimaryChannel,
  (channel) => channel && channel.status,
);

// The following selectors select messages, but they live in this channels selector.
// The reason for this is that they are channel specific selectors. Additionally,
// including these selectors in the messages reducer creates a cyclic dependency.

const extractChannelId = (_, channelId: PrimaryKey) => channelId;

export const getChannelById = createSelector(
  getChannelsEntities,
  extractChannelId,
  (channels, channelId) => {
    const channel = channels[channelId];
    if (!channel) throw new Error(`Channel id=${channelId} not found in store`);
    return channel;
  },
);

export const getHasChannelMessageDraft = createSelector(
  extractChannelId,
  (state: Store) => state.store.messageDrafts.byId,
  (channelId, messageDrafts) => {
    return messageDrafts.hasOwnProperty(channelId);
  },
);

export const getChannelMessageDraft = createSelector(
  extractChannelId,
  (state: Store) => state.store.messageDrafts.byId,
  (channelId, messageDrafts) => {
    const messageDraft = messageDrafts[channelId];
    if (!messageDraft) return '';
    return messageDraft.draft;
  },
);

export const getPrimaryMessages = createSelector(
  getPatientPrimaryChannel,
  getMessages,
  (channel, messages) => {
    if (!channel) return [];
    const channelMessages = channel.messages
      .map((messageId) => messages[messageId])
      // Cast occurs because TS is not smart enough to type narrow after the below filter
      .filter((m) => m) as Message[];
    return channelMessages.sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0));
  },
);

export const getEventMessages = createSelector(
  getPatientEventChannel,
  getMessages,
  (channel, messages) => {
    if (!channel) return [];
    const channelMessages = channel.messages
      .map((messageId) => messages[messageId])
      // Cast occurs because TS is not smart enough to type narrow after the below filter
      .filter((m) => m) as Message[];
    return channelMessages.sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0));
  },
);

export const getInternalMessages = createSelector(
  getPatientInternalChannel,
  getMessages,
  (channel, messages) => {
    if (!channel) return [];
    const channelMessages = channel.messages
      .map((messageId) => messages[messageId])
      // Cast occurs because TS is not smart enough to type narrow after the below filter
      .filter((m) => m) as Message[];
    return channelMessages?.sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0));
  },
);

export const hasMorePrimaryMessages = createSelector(
  getPatientPrimaryChannel,
  (channel) => channel.hasMoreMessages,
);

export const getPatientMessages = createSelector(
  getPrimaryMessages,
  getEventMessages,
  hasMorePrimaryMessages,
  (primaryMessages, eventMessages, hasMorePrimaryMessages) => {
    // Sort messages by timestamp, prioritizing primary messages when two messages have the
    // same timestamp
    const sortedMessages = primaryMessages.concat(eventMessages).sort((a, b) => {
      if (a.timestamp !== b.timestamp) return a.timestamp - b.timestamp;
      // For messages with same timestamp, return primary message first
      if (belongsInPrimaryChannel(a)) return -1;
      return 1;
    });
    const firstPrimaryMessageIndex = sortedMessages.findIndex((message) =>
      belongsInPrimaryChannel(message),
    );

    // Drop event (non-primary) messages earlier than the day of the earliest primary message
    // unless there's no more earlier primary messages, in which case we include all sorted
    // event messages.
    // We drop older event messages since they might be from even older days than the previous
    // primary message _as long as there are more primary channel messages to be loaded_.
    // When we reach the oldest message in the primary channel, we will then show the
    // rest of the loaded event messages
    return dropWhile(
      sortedMessages,
      (message) =>
        hasMorePrimaryMessages &&
        !belongsInPrimaryChannel(message) &&
        !isSameDay(message, sortedMessages[firstPrimaryMessageIndex]),
    );
  },
);

export const getPendingMessages = (state: Store) => state.store.pendingMessages.byId;

export const getPrimaryPendingMessages = createSelector(
  getPatientPrimaryChannel,
  getPendingMessages,
  (channel, pendingMessages) => {
    const channelPendingMessages =
      channel.pendingMessages?.map((pendingMessageId) => pendingMessages[pendingMessageId]) ?? [];
    return channelPendingMessages.sort((a, b) => a.timestamp - b.timestamp);
  },
);

export const getInternalPendingMessages = createSelector(
  getPatientInternalChannel,
  getPendingMessages,
  (channel, pendingMessages) => {
    const channelPendingMessags =
      channel.pendingMessages?.map((pendingMessageId) => pendingMessages[pendingMessageId]) ?? [];
    return channelPendingMessags.sort((a, b) => a.timestamp - b.timestamp);
  },
);

export const hasPrimaryPendingMessages = createSelector(
  getPrimaryPendingMessages,
  (pendingMessages) => pendingMessages.length > 0,
);

export const hasInternalPendingMessages = createSelector(
  getInternalPendingMessages,
  (pendingMessages) => pendingMessages.length > 0,
);

export const getPatientIdByChannelId = createSelector(
  getChannelById,
  (channel) => channel.patientId,
);

export default channels.reducer;
