import { createAsyncThunk, createEntityAdapter, createSlice, Dictionary } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { PharmacyPayload } from '../types/grdn';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { Pharmacy } from 'types/tables/pharmacies';
import { Store } from 'types/redux';

const prefix = 'pharmacy';
export enum FetchPharmacyRejectionType {
  Generic = 'Unable to complete patient tickler.',
}

export const fetchPharmacyByPatientId = createAsyncThunk<
  PharmacyPayload,
  string,
  {
    rejectValue: { rejectionType: FetchPharmacyRejectionType };
  }
>(`${prefix}/fetchByPatientId`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.pharmacy(patientId);
    if (isSuccessResponse(res) && res.data) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: FetchPharmacyRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: FetchPharmacyRejectionType.Generic });
  }
});

export const pharmacyAdapter = createEntityAdapter<Pharmacy>({});
export const pharmacies = createSlice({
  name: 'pharmacies',
  initialState: pharmacyAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPharmacyByPatientId.fulfilled, (state, action) => {
      //check if real pharmacy, only store if real
      if ('phoneNumber' in action.payload) {
        const thisPharmacy: Pharmacy = action.payload;
        thisPharmacy.id = action.meta.arg;
        pharmacyAdapter.upsertOne(state, thisPharmacy);
      }
    });
  },
});

export const pharmacySelectors = pharmacyAdapter.getSelectors<Store>((state) => state.pharmacies);
export const getPatientPharmacy = createSelector(
  (_, id: string) => id,
  pharmacySelectors.selectEntities,
  (id: string, pharmacies: Dictionary<Pharmacy>) => pharmacies[id],
);

export default pharmacies.reducer;
