import { createSelector } from 'reselect';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { Store } from 'types/redux';
import { ForeignKey } from 'types/tables/base';
import { Profile, profileIsError } from 'types/tables/profiles';

export const profilesAdapter = createEntityAdapter<Profile>({});

export const profilesSelectors = profilesAdapter.getSelectors<Store>((state) => state.profiles);

export const getPatientProfile = createSelector(
  (_, patientId: ForeignKey) => patientId,
  profilesSelectors.selectAll,
  (patientId, profiles: Profile[]) =>
    profiles.find((profile) => profile.patientId === patientId) || {
      isError: true,
    },
);

export const getPatientProfileId = createSelector(getPatientProfile, (profile) =>
  profileIsError(profile) ? undefined : profile.id,
);

export const getPatientHasHadVideoVisit = createSelector(getPatientProfile, (profile) =>
  profileIsError(profile) ? undefined : profile.hasHadVideoVisits,
);

export const getPatientDisplayName = createSelector(getPatientProfile, (profile) => {
  if (profileIsError(profile)) return undefined;
  if (profile.preferredName && profile.pronouns)
    return `${profile.preferredName} ${profile.lastName} (${profile.pronouns})`;
  if (profile.preferredName) return `${profile.preferredName} ${profile.lastName}`;
  if (profile.pronouns) return `${profile.firstName} ${profile.lastName} (${profile.pronouns})`;
  return `${profile.firstName} ${profile.lastName}`;
});

export const getPatientTimeZone = createSelector(getPatientProfile, (profile) => {
  return profileIsError(profile) ? undefined : profile.patientLocation.timeZoneId;
});

export const getPatientState = createSelector(getPatientProfile, (profile) => {
  return profileIsError(profile) ? undefined : profile.patientLocation.state;
});

export const getPatientPrimaryInsuranceCard = createSelector(getPatientProfile, (profile) =>
  profileIsError(profile) ? undefined : profile.primaryInsuranceCard,
);

export const getPatientAppLanguage = createSelector(getPatientProfile, (profile) =>
  profileIsError(profile) ? undefined : profile.appLanguage,
);

export const getPatientPrimaryProviderId = createSelector(getPatientProfile, (profile) =>
  !profileIsError(profile) && 'primaryProviderId' in profile
    ? profile.primaryProviderId
    : undefined,
);
