import React from 'react';
import MessageContainer, { MessageContainerProps } from 'components/features/Chat/MessageContainer';
import { TextPrimaryMessage } from 'types/tables/messages';
import { Button } from 'components/design-system/Button/Button';
import { markdownFormatter } from 'lib/markdown';
import css from 'components/features/Chat/WelcomeCard.module.css';

const WelcomeCard = React.forwardRef<HTMLDivElement, MessageContainerProps>(
  (props: MessageContainerProps, ref) => {
    const { message, profile, previous, user } = props;
    const content = (
      <div ref={ref} className={css.welcomeCard} data-testid="WelcomeMessage">
        <span
          dangerouslySetInnerHTML={{
            __html: markdownFormatter.render((message as TextPrimaryMessage).data.text),
          }}
        />
        <div className={css.buttonContainer}>
          <Button onPress={() => undefined} isDisabled>
            Book First Visit
          </Button>
        </div>
      </div>
    );

    return (
      <MessageContainer
        message={message}
        profile={profile}
        previous={previous}
        user={user}
        content={content}
      />
    );
  },
);
WelcomeCard.displayName = 'WelcomeCard';

export default WelcomeCard;
